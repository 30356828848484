import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"
import MiniLayout from "../../components/minimalLayout"


const Parasite = ({data}) => {

  return (
    <Layout>
      <MiniLayout>

        <h2>parasite (project B)</h2>
        <p>— an organism that lives in or on an organism of another species (its host) and benefits by deriving nutrients at the other's expense.</p>
        <p>The browser is a fantastic creature; like a flower longing for rays of the sun, it feeds on files from the web; html, css, and js are essential nutrients and once obtained, the incoming bits are processed, and turned into colorful pixels. The browser blooms and the human marvels.</p>
        <p>This parasite attaches to the browser. From under its skin, it watches as the browser (its host) interacts with the servers of the world, receives files through the network and renders content. It watches too, as we (the human species) engage with the interface; it listens as we click, scroll, copy, and paste.</p>
        <p>What are the parasite’s objectives? Which information does it seek and how does it interfere in its host’s activities? What are the resulting symptoms — how is the browser’s behavior altered? Does the parasite do harm or do good? Or does it merely coexist?</p>
        <p>Build a Browser Extension (the parasite), and unleash it.</p>
        <br></br>
        <p>You <b>may</b> work on this project as a <b>team</b> (of two).</p>

        <br></br>
        <p>Don't hesitate to ask me any <i>'Is it technically possible for a Chrome extenion to...'</i> question. The asnwer will mostly be yes, and I will point you to the right resources.</p>

        <br></br>
        <p>some inspiration:</p>
        <ul>
          <li>
            <p><a href="https://ch1n00.tumblr.com/" target="_blank">Internet Interventions</a> by Chino Kim (his code is <a target="_blank" href="https://github.com/chjno/100days">here</a>)</p>
          </li>
          <li>
            <p><a href="http://www.abstractbrowsing.net/" target="_blank">Abstract Browsing</a> by Rafaël Rozendaal</p>
          </li>
          <li>
            <p><a href="https://jonaslund.com/works/text-free-browsing/" target="_blank">Text Free Browsing</a> by Rafaël Rozendaal & Jonas Lund</p>
          </li>
        </ul>
        <br></br>
        

      </MiniLayout>
    </Layout>
  )
}



export default Parasite
